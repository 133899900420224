import {
  required,
  maxLength,
  alpha,
  helpers,
  requiredIf
} from 'vuelidate/lib/validators';

import {  onlyText, isLatitude, isLongitude } from '../funciones';

const LaitudeNotReq = (value) => !helpers.req(value) || isLatitude(value);
const LongitudeNotReq = (value) => !helpers.req(value) || isLongitude(value);

const metricsWithSymbol = (value) => /^([-]{1})?(?:[1-9][0-9]{0,15}|0)(\.[1-9])?(\.\d{1,7})?$/.test(value);
const metricsWSNotReq = (value) => !helpers.req(value) || metricsWithSymbol(value);

//const textEspecials = (value) => /^[A-Z \u00f1\u00d1-\(\)]+$/g.test(value);

const alphaSpecials = (value) => /^[a-zA-Z \u00f1\u00d1,.\-\/\$\&\(\)]+$/g.test(value);


export default () => {
  return {
    puerto: {
      PortName: { required, alphaSpecials },
      PortCode: { required, alpha, maxLength: maxLength(8)},
      Latitude: { required: requiredIf('Longitud'), LaitudeNotReq, metricsWSNotReq },
      Longitude: { required: requiredIf('Latitud'), LongitudeNotReq, metricsWSNotReq },
    },
    pais: { required },
    estado: {  }
  }
}